function VideoDosAndTextSection({ scrollToForm }) {
  return (
    <section className="w-full flex flex-col md:flex-row py-6">
      <div className="w-full md:w-1/2 my-5">
        <img
          src="/easyappforzendeskgif01.gif"
          alt="Easyappforz apps para zendesk"
          className="w-full"
          style={{ height: '500px', objectFit: 'contain' }}
        />
      </div>
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center px-14">
      <div className="text-left">
        <p className="text-4xl font-semibold mr-16">Casos de uso</p>
        <p className="text-lg my-4 text-gray-700 mr-24">
          Utiliza tus propias APIs y presenta información relevante para el Agente en el Ticket:
        </p>
        <ul className="list-none space-y-4 mt-6">
          <li className="flex items-center">
            <img src="/user.png" alt="icono" className="w-6 h-6 mr-2"/>
            <span>Datós de tu Cliente (CRM)</span>
          </li>
          <li className="flex items-center">
            <img src="/orders.png" alt="icono" className="w-6 h-6 mr-2"/>
            <span>Consulta órdenes de pago, cobros, etc.</span>
          </li>
          <li className="flex items-center">
            <img src="/tracking.png" alt="icono" className="w-6 h-6 mr-2"/>
            <span>Estado o seguimiento de pedidos</span>
          </li>
          <li className="flex items-center">
            <img src="/knowledge.png" alt="icono" className="w-6 h-6 mr-2"/>
            <span>Artículos de tu base de conocimiento externa</span>
          </li>
          <li className="flex items-center">
            <img src="/ticket.png" alt="icono" className="w-6 h-6 mr-2"/>
            <span>Obtén datos de tu API usando los atributos del Ticket</span>
          </li>
        </ul>
        <div className="mt-8">
          <button
            className="bg-[#ffa100] text-[#0f2645] font-semibold py-2 px-4 rounded-md hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500" onClick={scrollToForm}
          >
            Quiero una demo
          </button>
        </div>
      </div>
    </div>
    </section>
  );
}

export default VideoDosAndTextSection;
