function Banner() {
  return (
    <section className="w-screen h-[200px] bg-[#0f2645] flex flex-col items-center justify-center">
      <h1 className="text-5xl font-bold">
        <span className="text-[#ffa100]">easy</span>
        <span className="text-white">app</span>
      </h1>
      <p className="text-lg text-white mt-4">
        La forma más fácil de construir Apps para Zendesk en sólo minutos.
      </p>
    </section>
  );
}

export default Banner;