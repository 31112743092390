import { useRef } from 'react';
import Banner from './components/Banner';
import VideoDosAndTextSection from './components/VideoDosAndTextSection';
import VideoUnoAndTextSection from './components/VideoUnoAndTextSection';
import FormSuscribe from './components/FormSuscribe';
import Footer from './components/Footer';
import './App.css';

function App() {
  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div className="flex flex-col min-h-screen">
      <Banner />
      <div className="mx-auto max-w-screen-xl px-4">
        <VideoUnoAndTextSection />
      </div>
      <div className="w-full px-4 bg-gray-200">
          <VideoDosAndTextSection scrollToForm={scrollToForm} />
      </div>
      <div className="mx-auto max-w-screen-xl px-4" ref={formRef}>
          <FormSuscribe />
      </div>
      <Footer />
    </div>
  );
}

export default App;