import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function FormSuscribe() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
    .sendForm('service_v0wievl', 'template_iv613uo', form.current, {
      publicKey: 'nwEMPU_WXjR95gpl_',
    })
    .then(
      () => {
        setModalMessage("Solicitud de acceso anticipado realizada con éxito");
        setIsModalOpen(true);
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
          setModalMessage("Algo salió mal, por favor intentalo más tarde");
          setIsModalOpen(true);
          setIsLoading(false);
      });
      e.target.reset();
};

  return (
    <section className="w-full flex flex-col md:flex-row py-8">
      <div className="w-full md:w-1/2 flex flex-col justify-center items-start pr-14">
        <h2 className="text-4xl font-semibold mb-4">Sé de los primeros en obtenerla</h2>
        <p className="text-lg text-gray-700 mb-4">
          EasyApp está disponible en Acceso anticipado, queremos ofrecerte la mejor experiencia low-code, solicita tu acceso a través del formulario.
        </p>
        <p className="text-lg text-gray-700">¡Hablamos pronto!</p>
      </div>
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center bg-white shadow-lg rounded-lg p-8">
        <h2 className="text-2xl font-semibold mb-6">
          Completa el formulario y recibe tu acceso
        </h2>
        <form ref={form} className="space-y-4 w-full" onSubmit={sendEmail}>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="nombre">
            Tu Nombre
            </label>
            <input
              type="text"
              name="nombre"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-[#0f2645] focus:border-[#0f2645] sm:text-sm p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="email">
            Email*
            </label>
            <input
              type="email"
              name="email"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-[#0f2645] focus:border-[#0f2645] sm:text-sm p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="phone">
              Número de contacto (celular)*
            </label>
            <input
              type="text"
              name="phone"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-[#0f2645] focus:border-[#0f2645] sm:text-sm p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="company">
              Nombre de empresa*
            </label>
            <input
              type="text"
              name="company"
              required
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-[#0f2645] focus:border-[#0f2645] sm:text-sm p-2"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700" htmlFor="message">
              Comentarios
            </label>
            <textarea name="message" className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-[#0f2645] focus:border-[#0f2645] sm:text-sm p-2"/>
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-[#0f2645] text-white py-2 px-4 rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#0f2645]"
              disabled={isLoading}>
              {isLoading ? 'Enviando...' : 'Enviar'}
            </button>
          </div>
        </form>
      {/* Modal Component */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-lg w-full overflow-y-auto p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">{modalMessage}</h2>
            <button 
              className="mt-4 bg-orange-600 text-white py-2 px-4 rounded-md" 
              onClick={() => setIsModalOpen(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
      </div>
    </section>
  );
}

export default FormSuscribe;
