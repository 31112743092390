function VideoUnoAndTextSection() {
  return (
    <section className="w-full flex flex-col md:flex-row py-8">
      <div className="w-full md:w-1/2 flex flex-col justify-center items-start pr-14">
        <h2 className="text-4xl font-semibold mb-4">No necesitas saber programación</h2>
        <p className="text-lg mt-6">En 4 pasos crea tu primer App en Zendesk para que los Agentes la usen:</p>
        <ul className="custom-list list-none space-y-4 relative mt-6">
            <li className="flex items-center relative">
              <div className="w-8 h-8 flex items-center justify-center rounded-full bg-[#ffa100] text-white font-bold mr-4 relative z-10">
                1
              </div>
              <span className="text-sm">Crea tu flujo de consulta de datos</span>
            </li>
            <li className="flex items-center relative">
              <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 text-gray-600 font-bold mr-4 relative z-10">
                2
              </div>
              <span className="text-sm">Utiliza tus APIs</span>
            </li>
            <li className="flex items-center relative">
              <div className="w-8 h-8 flex items-center justify-center rounded-full bg-gray-200 text-gray-600 font-bold mr-4 relative z-10">
                3
              </div>
              <span className="text-sm">Elige que datos mostrar al Agente en el ticket</span>
            </li>
            <li className="flex items-center relative">
              <div className="w-8 h-8 flex items-center justify-center rounded-full bg-[#0f2645] text-gray-100 font-bold mr-4 relative z-10">
                4
              </div>
              <span className="text-sm">¡Confirma y despliega!</span>
            </li>
          </ul>
      </div>
      <div className="w-full md:w-2/2">
        <img
          src="/easyappgif01.gif"
          alt="Easyappforz apps para zendesk"
          className="w-full"
          style={{ height: 'auto', objectFit: 'contain' }}
        />
      </div>
  </section>
  );
}

export default VideoUnoAndTextSection;
