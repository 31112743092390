import React, { useState } from 'react';

function Footer() {
  const [isOpenQuienesSomos, setIsOpenQuienesSomos] = useState(false);
  const [isOpenLoQueOfrecemos, setIsOpenLoQueOfrecemos] = useState(false);
  const [isOpenPreguntasFrecuentes, setIsOpenPreguntasFrecuentes] = useState(false);

  return (
    <div className="bg-gray-900 text-white py-8 px-6">
      <div className="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 justify-center items-center">
        <div>
          <h4 className="text-xl font-semibold mb-4">EasyApp for Zendesk</h4>
          <p className='text-sm mb-2'>La forma más fácil que tienen los administradores de Zendesk para construir Apps.</p>
        </div>
        <div className='place-content-center'>
          <h4 className="text-xl font-semibold mb-4">El equipo</h4>
          <p className="cursor-pointer text-sm mb-2" onClick={() => setIsOpenQuienesSomos(true)}>Quienes somos</p>
          <p className="cursor-pointer text-sm mb-2" onClick={() => setIsOpenLoQueOfrecemos(true)}>Lo que ofrecemos</p>
        </div>
        <div>
          <h4 className="text-xl font-semibold mb-4">Contacto</h4>
          <p className='text-sm mb-2'>Para dudas escribe a contacto@easyappforz.com</p>
          <p className="cursor-pointer text-sm mb-2" onClick={() => setIsOpenPreguntasFrecuentes(true)}>Preguntas frecuentes</p>
        </div>
      </div>

      {/* Modal Quienes Somos */}
      {isOpenQuienesSomos && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-2xl w-full overflow-y-auto p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">¿Quienes somos?</h2>
            <p>
              Somos un equipo con más de 15 años de experiencia en CRM, Zendesk, Salesforce, Desarrollo web, Arquitectura en la nube, CX y consultoría en general. A lo largo de nuestra trayectoria, hemos ejecutado proyectos de implementación CRM y aplicaciones web para empresas de distintos rubros, además de trabajar en soluciones de telefonía en la nube, optimización de procesos y diseño de experiencias centradas en el cliente.
              <br></br><br></br>
              Con más de 20 proyectos exitosamente implementados, hemos decidido desarrollar una nueva forma de crear aplicaciones para Zendesk, la cuál es especialmente útil para administradores y consultores de esta plataforma, ya que permite hacer el trabajo más simple pero eficaz al integrar y presentar la información de sistemas o APIs externas a los agentes dentro del ticket en Zendesk.
            </p>
            <button className="mt-4 bg-[#ffa100] text-white py-2 px-4 rounded-md" onClick={() => setIsOpenQuienesSomos(false)}>
              Cerrar
            </button>
          </div>
        </div>
      )}

      {/* Modal Lo Que Ofrecemos */}
      {isOpenLoQueOfrecemos && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-2xl w-full overflow-y-auto p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Lo que ofrecemos</h2>
            <p>
              EasyApp es una solución innovadora que permite implementar aplicaciones en Zendesk en solo minutos, sin necesidad de conocimientos de programación. Solo necesitas una comprensión básica de cómo realizar llamadas a una API, y nuestra herramienta te guiará paso a paso para desplegar una aplicación al lado del ticket. Así, los agentes podrán visualizar la información que hayas configurado previamente utilizando tu API.
            </p><br/>
            <p>EasyApp es sencillo, versátil y rápido. Además, garantiza la seguridad de tus datos: toda la información permanece en tu propia instancia de Zendesk, sin almacenarse en bases de datos externas.</p>
            <br/>
            <button className="mt-4 bg-[#ffa100] text-white py-2 px-4 rounded-md" onClick={() => setIsOpenLoQueOfrecemos(false)}>
              Cerrar
            </button>
          </div>
        </div>
      )}

      {/* Modal Preguntas Frecuentes */}
      {isOpenPreguntasFrecuentes && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-4xl w-full overflow-y-auto p-8 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">Preguntas Frecuentes</h2>
            <ul className="list-disc pl-5">
              <li><strong>¿Debo pagar algo para tener el acceso anticipado?</strong> No, solo completas el formulario y nos comunicaremos contigo para darte el acceso.</li><br/>
              <li><strong>¿Los datos que configuro de mi API se almacenan en una base de datos externa?</strong> No, todos tus datos se almacenan en Zendesk, no conectamos o enviamos tu información a servicios externos.</li><br/>
              <li><strong>¿Puedo crear aplicaciones de cualquier tipo?</strong> De momento solo puedes crear aplicaciones que llamen a APIS y desees que muestren información en el Ticket para que tu agente pueda verlo.</li><br/>
              <li><strong>¿Tengo que escribir código en algún momento?</strong> No, la idea EasyApp es que sea una herramienta No-Code y fácil de usar para Administradores y Consultores de Zendesk.</li><br/>
              <li><strong>¿Puedo instalarla en Sandbox?</strong> Sí, puedes instalarla en cualquier instancia de Zendesk que tenga al menos en su suscripción custom objects. Consulta el detalle de planes para Zendesk <a href="https://support.zendesk.com/hc/es/articles/4408893293466-Acerca-de-los-tipos-de-planes-de-Zendesk-Support" target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">aquí</a>.</li><br/>
              <li><strong>¿Si quiero algo más personalizado ustedes lo hacen?</strong> De momento solo nos enfocamos en el desarrollo y evolución de EasyApp, si lo que necesitas está asociado a esta app sí, podemos ayudarte.</li><br/>
              <li><strong>¿Implementan Zendesk?</strong> Sí, somos Administradores y desarrolladores certificados en Zendesk.</li><br/>
              <li><strong>¿Esta app se puede instalar en otro sistema que no sea Zendesk?</strong> No, solo está disponible para Zendesk.</li><br/>
              <li><strong>¿Debo agregar mi tarjeta de crédito para el Acceso anticipado?</strong> No, solo completas el formulario y nos pondremos en contacto contigo.</li><br/>
              <li><strong>¿Cuándo estará disponible desde el Marketplace de Zendesk?</strong> La fecha estimada de salida por el Marketplace a todo público está para finales de Octubre del 2024.</li><br/>
              <li><strong>¿Si tengo dudas a quién puedo escribir?</strong> Envíanos un correo a contacto@easyappforz.com y con gusto atenderemos tus dudas.</li><br/>
            </ul>
            <button className="mt-4 bg-[#ffa100] text-white py-2 px-4 rounded-md" onClick={() => setIsOpenPreguntasFrecuentes(false)}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;